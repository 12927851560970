
// https://github.com/mui-org/material-ui/blob/master/examples/gatsby/src/theme.js

import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '4rem' // Default 6rem
    },
    h2: {
      fontSize: '3.25rem' // Default 3.75rem
    },
    h3: {
      fontSize: '2.75rem' // Default 3rem
    },
  }
});

// console.log('gutterBottom', theme)

export default theme;
