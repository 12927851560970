
// https://github.com/mui-org/material-ui/blob/master/examples/gatsby/plugins/gatsby-plugin-top-layout/gatsby-browser.js
import React from 'react'
import RootElement from './src/layout/RootElement'

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>;
};

require('prismjs/themes/prism.css')
require("prismjs/plugins/command-line/prism-command-line.css")
